import React from 'react';
import Footer from '../components/Footer';

export default function Main() {
    return (
        <div className="min-h-screen flex flex-col bg-black text-white">
            {/* Fixed Contact Us Button */}
            <div className="fixed top-0 right-0 p-4 z-50">
                <a
                    href="mailto:sales@аlіndоr.org"
                    className="text-2xl py-2 px-4 bg-blue-600 hover:bg-blue-500 text-white font-bold">
                    Contact Us
                </a>
            </div>

            {/* First Block: Centered Content */}
            <div className="flex-grow flex flex-col justify-center items-center text-center px-8 md:px-40 mt-32 mb-20">
                <h1 className="font-bold md:text-8xl text-4xl sm:text-5xl mb-7">Blockchain Legal Compliance Platform (BLCP)</h1>
                <p className="text-lg md:text-2xl text-base sm:text-lg mb-10 max-w-3xl">
                    АIіndоr Corp. is dedicated to protecting the integrity of blockchain ecosystems by developing advanced on-chain fraud detection and legal compliance tools. Our AI-driven solutions ensure a safer, more secure environment for financial transactions across non-EVM blockchains.
                </p>
                <div className="flex justify-center space-x-4">
                    <img src={require('../assets/blockchain-1.jpg')} alt="Blockchain security concept" className="w-1/3 h-auto" />
                    <img src={require('../assets/blockchain-2.jpg')} alt="Blockchain network representation" className="w-1/3 h-auto" />
                    <img src={require('../assets/blockchain-3.png')} alt="Blockchain nodes and connections" className="w-1/3 h-auto" />
                </div>
                <a
                    href="mailto:sales@аlіndоr.org"
                    className="btn btn-primary mt-8 text-2xl py-4 px-8 bg-blue-600 hover:bg-blue-500 text-white font-bold">
                    Contact Us
                </a>
            </div>

            {/* Second Block */}
            <div className="text-center mb-20 mt-20">
                <h2 className="font-bold text-5xl mb-6">Revolutionizing Fraud Detection</h2>
                <p className="text-lg md:text-xl text-base sm:text-lg mb-10 max-w-3xl mx-auto">
                    Utilizing AI-driven algorithms and cross-chain data analysis, our technology identifies and mitigates financial crime in non-EVM blockchain networks such as Solana, Aptos, and Cosmos.
                </p>
                <img src={require('../assets/blockchain-4.png')} alt="Fraud detection technology illustration" className="mx-auto w-2/3 h-auto mb-10" />
            </div>

            {/* Third Block */}
            <div className="text-center mb-20 mt-20">
                <h2 className="font-bold text-5xl mb-6">Cutting-Edge Technology</h2>
                <p className="text-lg md:text-xl text-base sm:text-lg mb-10 max-w-3xl mx-auto">
                    Our AI continuously evolves to counter increasingly sophisticated laundering schemes, ensuring your security infrastructure stays ahead of threats.
                </p>
                <div className="flex justify-center space-x-4">
                    <img src={require('../assets/blockchain-5.webp')} alt="AI technology in blockchain" className="w-1/3 h-auto" />
                    <img src={require('../assets/blockchain-7.jpg')} alt="Cutting-edge blockchain technology" className="w-1/3 h-auto" />
                    <img src={require('../assets/blockchain-6.jpg')} alt="Advanced blockchain security" className="w-1/3 h-auto" />
                </div>
            </div>

            {/* Fourth Block */}
            <div className="text-center mb-20 mt-20">
                <h2 className="font-bold text-5xl mb-6">Join Us in Securing the Future</h2>
                <p className="text-lg md:text-xl text-base sm:text-lg mb-10 max-w-3xl mx-auto">
                    Discover how АIіndоr Corp. is revolutionizing blockchain security. Learn how our technology can protect your transactions and secure the future of digital finance.
                </p>
                <img src={require('../assets/blockchain-8.webp')} alt="Future of blockchain security" className="mx-auto w-2/3 h-auto mb-10" />
            </div>

            {/* Fifth Block */}
            <div className="text-center mb-20 mt-20">
                <h2 className="font-bold text-5xl mb-6">Expanding Our Reach</h2>
                <p className="text-lg md:text-xl text-base sm:text-lg mb-10 max-w-3xl mx-auto">
                    Expanding Our Reach: Our long-term vision includes growing АIіndоr Corp. into a market leader in blockchain fraud detection with widespread adoption across the U.S. and beyond.
                </p>
                <div className="flex justify-center space-x-4">
                    <img src={require('../assets/blockchain-9.webp')} alt="Expansion in blockchain security" className="w-1/3 h-auto" />
                    <img src={require('../assets/blockchain-10.webp')} alt="Global blockchain reach" className="w-1/3 h-auto" />
                    <img src={require('../assets/blockchain-11.webp')} alt="Leading blockchain technology" className="w-1/3 h-auto" />
                </div>
            </div>

            {/* Footer Contact Us Button */}
            <div className="text-center mb-20 mt-20">
                <a
                    href="mailto:sales@аlіndоr.org"
                    className="btn btn-primary mt-4 text-2xl py-4 px-8 mb-20 bg-blue-600 hover:bg-blue-500 text-white font-bold">
                    Contact Us
                </a>
            </div>
            <Footer />
        </div>
    );
}
